import * as React from 'react';
import { routeConfig } from '../../../../__config__/routes';
import { Heading } from '../../text/Heading';
import { Paragraph } from '../../text/Paragraph';
import { AdvertisingPageSection } from '../AdvertisingPageSection';

export const KjopAD: React.FC = () => {
  return (
    <AdvertisingPageSection
      logoWithManualLabel={false}
      style={{ marginTop: 0 }}
      readmoreLink={routeConfig.kjop.url}
      logoUrl="https://cdn.reeltime.no/pm_assets/logo/pm_versjon2.svg"
      videoUrl="https://player.vimeo.com/video/515185604?autoplay=1"
    >
      <Heading tag="h2" center={true}>
        PrivatMegleren KJØP
      </Heading>
      <Paragraph center={true} style={{ maxWidth: '500px', margin: '0 auto' }}>
        Få hjelp og taktisk rådgivning på leting etter din neste eiendom. Med
        tjenesten KJØP får du bistand til akkurat til det du måtte ønske ved
        kjøp av eiendom.
      </Paragraph>
    </AdvertisingPageSection>
  );
};
