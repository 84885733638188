import * as React from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import { animated } from 'react-spring';

import { useMutation, useQuery } from '@apollo/client';
import { SOLDPRICE_PROPERTY_QUERY } from '../../../../client/__graphql__/queries';
import { SOLDPRICE_OSLO_MUTATION } from '../../../../client/__graphql__/mutations';

import NoMatch from '../404Old';
import { Thanks } from '../../layout/Thanks';
import { Section } from '../../layout/Section';
import { Container } from '../../layout/Container';

import { Input } from '../../ui/form/Input';
import { FormError } from '../../ui/form/FormError';
import { Heading } from '../../ui/text/Heading';
import { Paragraph } from '../../ui/text/Paragraph';
import { Form, FormRow } from '../../ui/form/Form';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Button } from '../../ui/button/Button';
import { Hero } from '../../ui/hero/Index';
import { KjopAD } from '../../ui/advertising/ads/Kjop';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { Checkbox } from '../../ui/form/Checkbox';
import { Logo } from '../../ui/logo/office/Index';

import { useForm } from '../../../utils/hooks/useForm';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { useFadeTransition } from '../../../utils/hooks/useFadeTransition';
import { createGTMEvent } from '../../../utils/tracking/gtm';

const SoldDM: React.FC = () => {
  const params = useParams<{ sguid: string }>();
  const [posted, setPosted] = React.useState(false);

  const [contactNearby, setContactNearby] = React.useState(false);

  const { data, loading: propertyLoading } = useQuery(
    SOLDPRICE_PROPERTY_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          sguid: params.sguid
        }
      }
    }
  );

  if (propertyLoading) {
    return null;
  }

  if (!data || !data.property) {
    return <NoMatch />;
  }

  const { fadeIn: pageFadeIn } = useFadeTransition({
    immidiate: !propertyLoading && data
  });

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });

  const [lead, { loading, error }] = useMutation(SOLDPRICE_OSLO_MUTATION, {
    onCompleted: ({ lead: { soldprice } }) => {
      if (soldprice && soldprice.success) {
        window.scroll(0, 0);
        setPosted(true);
        setContactNearby(false);
        createGTMEvent({
          event: 'pmFormSubmission',
          gaCategory: 'RT_SOLGTPRIS',
          gaAction: 'RT_SEND_SOLGTPRIS',
          gaEvent: `RT_SEND_SOLGTPRIS_${params.sguid}`
        });
      }
    }
  });

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'zip',
        name: 'zip',
        placeholder: 'Eks. 0000',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: 'Eks. 00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      }
    ],
    submit: () => {
      const preparedFields = getFields(fields);
      lead({
        variables: {
          input: {
            identifier: params.sguid,
            leadType: 'SOLDPRICE',
            pageType: 'OBJEKT',
            phone: preparedFields.phone,
            zip: preparedFields.zip,
            referer: window.location.href
          },
          soldprice: {
            consents: {
              contactNearby: contactNearby
            }
          }
        }
      });
    }
  });

  return (
    <>
      <Hero
        style={{ ...pageFadeIn, marginTop: '66px' }}
        size="cover"
        height="233px"
        imageUrl="https://cdn.reeltime.no/pm_assets/img/bakgrunner/solddm2.jpg"
        opacity={1}
        alt="bilde"
      >
        <Container className="center">
          <LogoWrapper>
            <Logo alt="bilde av logo" />
          </LogoWrapper>
        </Container>
      </Hero>
      <Container style={{ ...pageFadeIn }}>
        <FormWrapper style={fadeOut}>
          <Section className="form-section" style={{ paddingBottom: '10px' }}>
            <Heading tag="h1" center={true}>
              Kom i kontakt
            </Heading>
            <Paragraph center>
              PrivatMegleren har nylig solgt eiendommen du har vist interesse
              for. Fyll ut skjemaet under for å komme i kontakt med megleren på
              oppdraget, eller med PrivatMegleren i ditt nærmiljø.
            </Paragraph>
            <Paragraph center>
              Fyll ut skjemaet under dersom du har spørsmål til megler om
              eiendommen, eller hvis du ønsker kontakt med PrivatMegleren i ditt
              nærområde.
            </Paragraph>
            <Paragraph center>
              Ønsker du bistand til søk og kjøp av eiendom? Se mer informasjon
              lengre ned på siden.
            </Paragraph>
          </Section>
          <Form onSubmit={handleSubmit} noValidate>
            {error &&
              error.graphQLErrors.map((error, index) => (
                <FormError key={`error_${index}`} message={error.message} />
              ))}
            {fields.map((item: any, index) => {
              return (
                <FormRow key={`field_${index}`}>
                  <Input
                    type={item?.type}
                    name={item?.name}
                    label={item?.label}
                    labelAfter={item?.labelAfter}
                    placeholder={item?.placeholder}
                    value={item?.value}
                    onChange={handleChange}
                    error={item?.error}
                  />
                </FormRow>
              );
            })}
            <ConsentWrapper>
              <FormRow>
                <Checkbox
                  checked={!contactNearby}
                  name="contactBroker"
                  onChange={() => setContactNearby(false)}
                  label="Ønsker kontakt med megler som solgte denne eiendommen"
                  labelPos="right"
                />
              </FormRow>
              <FormRow>
                <Checkbox
                  checked={contactNearby}
                  name="contactNearby"
                  onChange={() => setContactNearby(true)}
                  label="Ønsker kontakt med PrivatMegleren i mitt nærområde"
                  labelPos="right"
                />
              </FormRow>
            </ConsentWrapper>
            <ButtonGroup className="space-top-bottom">
              <Button
                type="primary"
                colorTheme="gold"
                disabled={loading}
                loading={loading}
              >
                Kontakt meg
              </Button>
              <ButtonLink
                as="link"
                to="/personvern"
                className="privacy-link-mobile"
              >
                Personvernpolicy
              </ButtonLink>
            </ButtonGroup>
          </Form>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h1" center={true}>
              Takk for din henvendelse
            </Heading>
          </Section>
        </Thanks>
        <KjopAD />
      </Container>
    </>
  );
};

export default SoldDM;

const ConsentWrapper = styled.div``;

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const FormWrapper = styled(animated.div)``;
